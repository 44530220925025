<template>
  <div id="user-profile">
    <nav
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <navbar />
    </nav>
    <b-row class="justify-content-center my-2">
      <b-col sm="12" md="11" lg="8">
        <center>
          <b-card-actions ref="hasil-card" title="Info Jadwal" action-collapse>
            <table class="table table-borderless d-table d-md-none">
              <tr>
                <td>Nama Jadwal</td>
                <th class="text-left">{{ jadwal?.name }}</th>
              </tr>

              <tr>
                <td>Paket</td>
                <th class="text-left">{{ jadwal?.paket?.name }}</th>
              </tr>
              <tr>
                <td>Tanggal Awal</td>
                <th class="text-left">{{ jadwal?.start_time }}</th>
              </tr>
              <tr>
                <td>Tanggal Akhir</td>
                <th class="text-left">{{ jadwal?.end_time }}</th>
              </tr>
            </table>
            <table class="table table-borderless d-none d-md-table">
              <tr>
                <td>Nama Jadwal</td>
                <th class="text-left">{{ jadwal?.name }}</th>
                <td>Paket</td>
                <th class="text-left">{{ jadwal?.paket?.name }}</th>
              </tr>
              <tr>
                <td>Tanggal Awal</td>
                <th class="text-left">{{ jadwal?.start_time }}</th>
                <td>Tanggal Akhir</td>
                <th class="text-left">{{ jadwal?.end_time }}</th>
              </tr>
              <tr v-if="isAllowedPembahasan && jadwal?.path_file_pembahasan">
                <td colspan="2">File Pembahasan Tryout</td>
                <th colspan="2">
                  <a
                    href="#"
                    class="text-primary"
                    @click.prevent="downloadFilePembahasan"
                  >
                    <strong>{{ filePembahasan }}</strong>
                  </a>
                </th>
              </tr>
            </table>
          </b-card-actions>
          <div class="" v-if="paketDetail.rasionalisasi_type == 'utbk'">
            <b-card-actions
              ref="rasionalisasi-card"
              title="Hasil Tryout"
              action-collapse
              ><h3>
                {{ hasilRasionalisasi?.title }}
                <b>{{ hasilRasionalisasi.name }}</b>
              </h3>
            </b-card-actions>
            <b-row class="justify-content-center">
              <b-col cols="12" md="6">
                <b-card no-body class="text-center">
                  <section
                    class="bg-primary text-center p-2"
                    style="border-radius: 5px"
                  >
                    <b-row>
                      <b-col md="6" class="text-white">
                        Rank Saya <br />
                        <b> {{ hasilRasionalisasi?.ranking?.ke }}</b
                        >/{{ hasilRasionalisasi?.ranking?.dari }}
                      </b-col>
                      <b-col md="6">
                        <b-button variant="outline-light" @click="lihatRank"
                          >Lihat Rank Lengkap</b-button
                        >
                      </b-col>
                    </b-row>
                  </section>
                </b-card>
              </b-col>
            </b-row>
            <div
              class=""
              v-if="hasilRasionalisasi.hasil_rasionalisasi[0] != null"
            >
              <b-card style="margin-left: 7%; margin-right: 7%">
                <b-row>
                  <b-col
                    md="6"
                    class="mt-3"
                    v-for="(item, i) in hasilRasionalisasi.hasil_rasionalisasi"
                    :key="i"
                  >
                    <table class="table table-bordered">
                      <tr>
                        <th
                          colspan="2"
                          class="text-center bg-primary text-white"
                        >
                          Pilihan {{ item.pilihan_ke }}
                        </th>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>{{ item.university_info.name }}</td>
                      </tr>
                      <tr>
                        <td>Target Jurusan</td>
                        <td>{{ item.university_info.major_name }}</td>
                      </tr>
                      <tr>
                        <td>Nilai Ambang Batas</td>
                        <td>{{ item.nilai_ambang_batas }}</td>
                      </tr>
                      <tr>
                        <td>Total Skor</td>
                        <td>{{ item.score_total }}</td>
                      </tr>
                      <tr>
                        <td>Peluang Lulus</td>
                        <td>
                          <b-badge
                            variant="light-danger"
                            class="ml-1"
                            v-if="item.peluang_lulus == false"
                            >Tidak Lolos</b-badge
                          >
                          <b-badge variant="light-success" class="ml-1" v-else
                            >Lolos</b-badge
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Peluang Masuk</td>
                        <td>
                          <b-badge variant="light-secondary" class="ml-1">{{
                            item.evaluasi.status_peluang_masuk
                          }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td>Pesan</td>
                        <td>
                          <p>{{ item.evaluasi.message }}</p>
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>
          <div class="" v-if="paketDetail.rasionalisasi_type == 'mandiri'">
            <b-card
              style="margin-left: 30%; margin-right: 30%"
              v-if="hasilRasionalisasi.ranking"
            >
              <b-card-body class="bg-primary">
                <b-row>
                  <b-col md="6" class="text-white">
                    Rank Saya <br />
                    <b> {{ hasilRasionalisasi?.ranking?.ke }}</b
                    >/{{ hasilRasionalisasi?.ranking?.dari }}
                  </b-col>
                  <b-col md="6">
                    <b-button variant="outline-light" @click="lihatRank"
                      >Lihat Rank Lengkap</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <div class="" v-if="hasilRasionalisasi != null">
              <b-card style="margin-left: 7%; margin-right: 7%">
                <b-row>
                  <b-col
                    md="6"
                    class=""
                    v-for="(item, i) in hasilRasionalisasi.selected_majors"
                    :key="i"
                  >
                    <table class="table table-bordered">
                      <tr>
                        <th
                          colspan="2"
                          class="text-center bg-primary text-white"
                        >
                          Pilihan {{ i + 1 }}
                        </th>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>{{ item.school.name }}</td>
                      </tr>
                      <tr>
                        <td>Target Jurusan</td>
                        <td>{{ item.name }}</td>
                      </tr>
                      <tr>
                        <td>Nilai Ambang Batas</td>
                        <td>
                          {{
                            item.passing_grade_percentage
                              ? item.passing_grade_percentage
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Skor</td>
                        <td>{{ item.nilai_akhir }}</td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>

          <b-card-actions
            ref="rasionalisasi-card"
            title="Hasil Tryout"
            action-collapse
            v-if="paketDetail.rasionalisasi_type == 'sma' && hasilRasionalisasi"
          >
            <b-table-simple responsive bordered>
              <b-tr style="background-color: #fef000">
                <b-th colspan="6" class="text-center">
                  <h1 class="text-dark">
                    BIMBINGAN BELAJAR EINSTEIN LEARNING PLUS
                  </h1></b-th
                >
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h2 class="text-dark">
                    {{ hasilRasionalisasi?.title }}
                  </h2></b-th
                >
              </b-tr>
              <b-tr>
                <th>Nama</th>
                <td colspan="5">{{ hasilRasionalisasi?.name }}</td>
              </b-tr>
              <b-tr>
                <th>Sekolah</th>
                <td colspan="5">
                  {{
                    hasilRasionalisasi?.school_origin
                      ? hasilRasionalisasi?.school_origin.nama_sekolah
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                >
              </b-tr>
              <b-tr>
                <b-th
                  rowspan="2"
                  class="text-center"
                  style="background-color: #bebfc3"
                  ><h3 class="text-primary"><b> TRY OUT</b></h3></b-th
                >
                <b-th
                  colspan="5"
                  class="text-center text-dark"
                  style="background-color: #a8a7a9"
                >
                  <b> NILAI PER MAPEL SATUAN </b>
                </b-th>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th class="text-primary">BENAR</b-th>
                <b-th class="text-primary">SALAH</b-th>
                <b-th>Nilai TO</b-th>
                <b-th>*KK</b-th>
                <b-th>Keterangan</b-th>
              </b-tr>
              <b-tr
                v-for="(item, i) in hasilRasionalisasi.nilai_per_mapel"
                :key="i"
              >
                <th>{{ item.ujian_name }}</th>
                <td>{{ item.jumlah_benar }}</td>
                <td>{{ item.jumlah_salah }}</td>
                <td>{{ item.nilai }}</td>
                <td>{{ item.grade ? item.grade : "-" }}</td>
                <td>{{ item.keterangan ? item.keterangan : "-" }}</td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6"></b-th>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>JUMLAH NILAI</th>
                <td colspan="5">{{ hasilRasionalisasi.jumlah_nilai }}</td>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>RATA-RATA NILAI</th>
                <td colspan="5">
                  {{ hasilRasionalisasi.nilai_rata.toFixed(2) }}
                </td>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>*KK RATA-RATA NILAI</th>
                <td colspan="5">{{ hasilRasionalisasi.nilai_rata_grade }}</td>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>RANGKING</th>
                <td colspan="5">{{ hasilRasionalisasi?.ranking?.ke }}</td>
              </b-tr>
            </b-table-simple>
          </b-card-actions>
          <!-- Kedinasan -->
          <b-card-actions
            ref="rasionalisasi-card"
            title="Hasil Tryout"
            action-collapse
            v-if="paketDetail.rasionalisasi_type == 'kedinasan'"
          >
            <b-table-simple responsive bordered>
              <b-tr style="background-color: #fef000">
                <b-th colspan="6" class="text-center">
                  <h1 class="text-dark">
                    BIMBINGAN BELAJAR EINSTEIN LEARNING PLUS
                  </h1></b-th
                >
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h2 class="text-dark">Kedinasan</h2></b-th
                >
              </b-tr>
              <b-tr>
                <th>Nama</th>
                <td colspan="5">{{ hasilRasionalisasi.user_name }}</td>
              </b-tr>
              <b-tr>
                <th>Asal Sekolah</th>
                <td colspan="5">
                  {{
                    hasilRasionalisasi.school_origin
                      ? hasilRasionalisasi.school_origin.nama_sekolah
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr>
                <th>Pilihan Sekolah Kedinasan</th>

                <td colspan="5">
                  {{
                    hasilRasionalisasi.selected_school
                      ? hasilRasionalisasi.selected_school?.school?.name
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                >
              </b-tr>
              <b-tbody v-for="(item, i) in hasilRasionalisasi.subcats" :key="i">
                <b-tr>
                  <b-th
                    colspan="6"
                    class="text-dark"
                    style="background-color: #a8a7a9"
                  >
                    <h4>
                      <b> {{ item.name }} </b>
                    </h4>
                  </b-th>
                </b-tr>
                <b-tr
                  v-if="item.items"
                  v-for="(data, index) in item.items"
                  :key="index"
                >
                  <b-th>{{ data.ujian_name }}</b-th>
                  <b-th>{{ data.nilai }}</b-th>
                  <b-th>{{
                    data.is_passed == true ? "Lulus" : "Tidak Lulus"
                  }}</b-th>
                </b-tr>
                <b-tr style="background-color: #c5d9f1">
                  <b-th class="text-danger">SKOR</b-th>
                  <b-th class="text-danger">{{
                    item.is_using_percentage == true
                      ? item.sum_percentage_nilai
                      : item.total_nilai
                  }}</b-th>
                  <b-th class="text-danger">{{
                    item.is_passed == true
                      ? "Lulus"
                      : "Tidak Lanjut Ke Tahap Selanjutnya"
                  }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-actions>
          <!-- Bintara -->
          <b-card v-if="paketDetail.rasionalisasi_type == 'bintara'">
            <b-table-simple responsive bordered>
              <b-tr style="background-color: #fef000">
                <b-th colspan="6" class="text-center">
                  <h1 class="text-dark">
                    BIMBINGAN BELAJAR EINSTEIN LEARNING PLUS
                  </h1></b-th
                >
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h2 class="text-dark">Bintara</h2></b-th
                >
              </b-tr>
              <b-tr>
                <th>Nama</th>
                <td colspan="5">{{ hasilRasionalisasi.user_name }}</td>
              </b-tr>
              <b-tr>
                <th>Asal Sekolah</th>
                <td colspan="5">
                  {{
                    hasilRasionalisasi.school_origin
                      ? hasilRasionalisasi.school_origin.nama_sekolah
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr>
                <th>Sekolah Kedinasan Pilihan</th>
                <td colspan="5">
                  {{ hasilRasionalisasi?.selected_school?.school?.name }}
                </td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                >
              </b-tr>
              <b-tbody v-for="(item, i) in hasilRasionalisasi.subcats" :key="i">
                <b-tr>
                  <b-th
                    colspan="6"
                    class="text-dark"
                    style="background-color: #a8a7a9"
                  >
                    <h4>
                      <b> {{ item.name }} </b>
                    </h4>
                  </b-th>
                </b-tr>
                <b-tr
                  v-if="item.items"
                  v-for="(data, index) in item.items"
                  :key="index"
                >
                  <b-th>{{ data.ujian_name }}</b-th>
                  <b-th colspan="2">{{ data.nilai }}</b-th>
                </b-tr>
                <b-tr style="background-color: #c5d9f1">
                  <b-th class="text-danger">SKOR</b-th>
                  <b-th class="text-danger">{{ item.total_nilai }}</b-th>
                  <b-th class="text-danger">{{
                    item.is_passed == true
                      ? "Lulus"
                      : "Tidak Lanjut Ke Tahap Selanjutnya"
                  }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>

          <b-card-actions
            ref="raport-card"
            title="Raport Hasil Tryout"
            action-collapse
          >
            <b-row>
              <b-col md="12">
                <div class="table-responsive" v-if="listRaport.length > 0">
                  <table class="table table-bordered responsive">
                    <tr class="bg-primary text-white" variant="primary">
                      <th>No</th>
                      <th>Jenis</th>
                      <th>Subtes</th>
                      <th>Jumlah Soal</th>
                      <th>B</th>
                      <th>S</th>
                      <th>K</th>
                      <th>T</th>
                      <th v-if="isAllowedPembahasan">Pembahasan</th>
                    </tr>
                    <tr
                      v-if="listRaport.scores[0] != null"
                      v-for="(item, i) in listRaport.scores"
                      :key="i"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.ujian_shortname }}</td>
                      <td>{{ item.ujian_name }}</td>
                      <td>{{ item.jumlah_soal }}</td>
                      <td>{{ item.jumlah_benar }}</td>
                      <td>{{ item.jumlah_salah }}</td>
                      <td>{{ item.jumlah_kosong }}</td>
                      <td>
                        <b-badge variant="success"> {{ item.nilai }} </b-badge>
                      </td>
                      <td v-if="isAllowedPembahasan">
                        <b-button
                          variant="outline-primary"
                          @click="lihatPembahasan(item)"
                          v-if="item.hasil_id != null"
                          >Pembahasan</b-button
                        >
                        <b-button variant="outline-primary" disabled v-else
                          >Pembahasan</b-button
                        >
                      </td>
                    </tr>
                    <tr
                      v-if="listRaport[0]?.scores[0] != null"
                      v-for="(item, i) in listRaport[0].scores"
                      :key="i"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.ujian_shortname }}</td>
                      <td>{{ item.ujian_name }}</td>
                      <td>{{ item.jumlah_soal }}</td>
                      <td>{{ item.jumlah_benar }}</td>
                      <td>{{ item.jumlah_salah }}</td>
                      <td>{{ item.jumlah_kosong }}</td>
                      <td>
                        <b-badge variant="success"> {{ item.nilai }} </b-badge>
                      </td>
                      <td v-if="isAllowedPembahasan">
                        <b-button
                          variant="outline-primary"
                          @click="lihatPembahasan(item)"
                          v-if="item.hasil_id != null"
                          >Pembahasan</b-button
                        >
                        <b-button variant="outline-primary" disabled v-else
                          >Pembahasan</b-button
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-center">Total</td>
                      <td colspan="2" class="text-center">
                        <b-badge variant="danger" class="text-center">
                          {{ sumScores }}</b-badge
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-actions>

          <br />
          <br />
        </center>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Navbar from "./components/Navbar.vue";
import vSelect from "vue-select";

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCardActions,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    Navbar,
    vSelect,
  },
  data() {
    return {
      listRaport: [],
      loading: false,
      sumScores: 0,
      jadwal: null,
    };
  },
  watch: {
    jadwal(val) {
      if (val) {
        this.getRasionalisasi();
        this.getHasil();
      }
    },
  },
  computed: {
    downloadFilePembahasan() {
      const link = document.createElement("a");
      link.href = `${process.env.VUE_APP_API_FILE}/${this.jadwal?.path_file_pembahasan}`;
      link.target = "_blank";
      link.click();
    },
    filePembahasan() {
      if (!this.jadwal || !this.jadwal?.path_file_pembahasan) return null;

      const arrayPath = this.jadwal?.path_file_pembahasan.split("/");

      return arrayPath[arrayPath.length - 1];
    },
    isAllowedPembahasan() {
      if (!this.jadwal) return false;

      return this.jadwal?.file_pembahasan == 1;
    },
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
    hasilRasionalisasi() {
      return this.$store.state.ujian.hasilRasionalisasi;
    },
  },

  methods: {
    lihatRank() {
      this.$router.push({
        name: "ranking-siswa-tryout",
        query: { p: this.$route.query?.p, j: this.$route.query?.j },
      });
    },
    lihatPembahasan(item) {
      this.$store.commit("ujian/SET_HASIL", item);
      this.$router.push({ name: "pembahasan-soal-tryout" });
    },
    getHasil() {
      // get raport
      let params = {
        paket_id: this.paketDetail.id,
        jadwal_id:
          this.jadwal != null ? this.jadwal.id : this.tryoutDetail.jadwal_id,
        kategori_paket_id: this.tryoutDetail.category_id,
      };
      this.$store
        .dispatch("raport/list", params)
        .then((res) => {
          this.listRaport = res.data.data;
          if (this.listRaport[0] != null) {
            var sumScores = this.listRaport[0].scores.reduce((total, item) => {
              return (total += parseInt(item.nilai));
            }, 0);
          } else {
            var sumScores = this.listRaport.scores.reduce((total, item) => {
              return (total += parseInt(item.nilai));
            }, 0);
          }
          this.sumScores = sumScores;
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
    },
    async getJadwal() {
      try {
        const response = await this.$store.dispatch(
          "jadwaladmin/indexId",
          this.$route.query?.j
        );
        this.jadwal = response.data;
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    getRasionalisasi() {
      let params = {
        paket_id: this.paketDetail.id,
        jadwal_id:
          this.jadwal != null ? this.jadwal.id : this.tryoutDetail.jadwal_id,
      };
      this.$store
        .dispatch("rasionalisasi/index", params)
        .then((res) => {
          const hasilRasionalisasi = res.data;

          // check jika ada sucat yang kosong, hilangkan aja
          if (hasilRasionalisasi.subcats) {
            hasilRasionalisasi.subcats = hasilRasionalisasi.subcats.filter(
              (subcat) => subcat.items.length > 0
            );
          }

          this.$store.commit(
            "ujian/SET_CURRENT_HASIL_RASIONALISASI",
            hasilRasionalisasi
          );
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
    },
  },

  created() {
    this.$store.commit("ujian/SET_CURRENT_HASIL_RASIONALISASI", null);
    if (!this.$route.query?.p || !this.$route.query?.j) {
      this.$router.go(-1);
    }
    this.getJadwal();
  },
};
</script>
